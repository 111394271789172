export function ListItem(props) {
  const { data } = props;
  return (
    <tbody>
      {data &&
        data?.map((item, index) => (
          <tr key={index} className="fs-6">
            <td>{index}</td>
            <td>{item?.title}</td>
            <td>{item?.productId?.title}</td>
            <td>{new Date(item?.startSale).toLocaleDateString("vi-VN")}</td>
            <td>{new Date(item?.endSale).toLocaleDateString("vi-VN")}</td>
            <td>
              {item?.productId?.price.toLocaleString("en-US", {
                style: "currency",
                currency: "VND",
              })}
            </td>
            <td>
              {item?.productId?.discount.toLocaleString("en-US", {
                style: "currency",
                currency: "VND",
              })}
            </td>
          </tr>
        ))}
    </tbody>
  );
}
