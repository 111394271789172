import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../features/product/productSlice";
import { selectProduct } from "../../features/product/productSlice";
import { DatePicker, Space } from "antd";
import { useFormik } from "formik";
import { object, string } from "yup";
import { axiosClient, config } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { ListItem } from "./ListItem";

const { RangePicker } = DatePicker;

let saleSchema = object().shape({
  title: string().required("Tiêu đề không được để trống"),
  product: string().required("Tiêu đề không được để trống"),
});

export default function FlashSale() {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [data, setData] = useState();

  const productState = useSelector(selectProduct);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      product: "",
    },
    validationSchema: saleSchema,
  });

  const handleAddData = async () => {
    const data = {
      title: formik.values.title,
      productId: formik.values.product,
      startSale: date?.[0]?.$d,
      endSale: date?.[1]?.$d,
    };
    await axiosClient
      .post(`sale/`, data, config)
      .then((res) => console.log(res.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const showButton = () => {
    return (
      <button
        onClick={() => handleAddData()}
        className="btn btn-primary"
        style={{ marginRight: "10px" }}
      >
        Thêm
      </button>
    );
  };

  useEffect(() => {
    axiosClient
      .get(`sale/`, config)
      .then((res) => setData(res.data.data))
      .catch((err) => toast.error(err));
  }, []);

  return (
    <div className="container">
      <h2 className="text-center">Chương trình Flash Sale</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="fs-5 ms-1">Nhập tên chương trình</label>
            <input
              type="text"
              onChange={formik.handleChange("title")}
              onBlur={formik.handleBlur("title")}
              val={formik.values.title}
              className="form-control"
              placeholder="Tên chương trình"
            />
            <div className="error">
              {formik.touched.title && formik.errors.title}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label className="fs-5 ms-1">Nhập đường dẫn sản phẩm</label>
            <select
              name="product"
              onChange={formik.handleChange("product")}
              onBlur={formik.handleBlur("product")}
              val={formik.values.product}
              className="form-control"
            >
              <option value="">Chọn sản phẩm</option>
              {productState &&
                productState?.data.map((item, index) => {
                  return (
                    <option value={item?._id} key={index}>
                      {item?.title}
                    </option>
                  );
                })}
            </select>
            <div className="error">
              {formik.touched.product && formik.errors.product}
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column">
            <label className="fs-5 ms-1">Thời gian sale</label>
            <Space direction="vertical" size={12}>
              <RangePicker onChange={setDate} />
            </Space>
          </div>
          <div className="col-12 d-flex align-items-center">
            <div className="mx-auto">{showButton()}</div>
          </div>
        </div>
      </form>

      <div className="mt-2 bg-white rounded-3 p-1">
        <label className="fs-4 fw-bold text-danger text-uppercase">
          Sản phẩm sale
        </label>
        <table className="table">
          <thead>
            <tr className="fs-5">
              <th>STT</th>
              <th>Chương trình sale</th>
              <th>Sản phẩm</th>
              <th>Ngày bắt đầu</th>
              <th>Ngày kết thúc</th>
              <th>Giá bán</th>
              <th>Giá giảm</th>
            </tr>
          </thead>
          <ListItem data={data} />
        </table>
      </div>
    </div>
  );
}
