import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { statusUser } from "../../features/auth/authSlice";
import { toast } from "react-toastify";

const columns = [
  {
    title: "Số thứ tự",
    dataIndex: "key",
  },
  {
    title: "Họ và tên",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
  },
  {
    title: "Trạng Thái",
    dataIndex: "status",
  },
  {
    title: "Ngày tạo",
    dataIndex: "date",
  },
];

export function ListItem({ data: [] }) {
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state?.customer?.customers);

  const handleStatus = async (_id, e) => {
    const isBlocked = e.target.value;
    const resultAction = await dispatch(statusUser({ _id, isBlocked }));
    const msg = resultAction.payload.msg;
    if (statusUser.fulfilled.match(resultAction)) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };
  const data = [];
  for (let i = 0; i < customerState?.length; i++) {
    data.push({
      key: i + 1,
      name: customerState[i].firstname,
      email: customerState[i].email,
      mobile: customerState[i].mobile,
      status: (
        <div className="d-flex text-center gap-2 align-items-center">
          <select
            className="form-select form-control"
            defaultValue={customerState[i]?.isBlocked}
            onChange={(e) => handleStatus(customerState[i]?._id, e)}
          >
            <option value="1">Active</option>
            <option value="0">Block</option>
          </select>
        </div>
      ),
      date: new Date(customerState[i].createdAt).toLocaleDateString("vi-VN"),
    });
  }
  return <Table columns={columns} dataSource={data} />;
}
