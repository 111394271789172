import { Input } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { TfiReload } from "react-icons/tfi";
import * as Yup from "yup";

export const Search = (props) => {
  const { handleSearch } = props;
  const [keySearch, setKeySearch] = useState("");
  const formSchema = Yup.object({
    // keySearch: Yup.number().min(10000, '*Ít nhất 5 kí tự').typeError('*Dữ liệu bắt buộc là số!'),
  });
  const handleClickSearch = () => {
    handleSearch(keySearch.trim());
  };
  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      keySearch: keySearch,
    },
    validationSchema: formSchema,
  });
  const handleRefreshData = () => {
    window.location.reload();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(keySearch.trim());
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-end gap-2">
      <div className="relative d-flex items-center">
        <Input
          type="text"
          className=""
          placeholder="Tìm kiếm....."
          value={keySearch}
          onChange={(e) => setKeySearch(e.target.value)}
          onBlur={formik.handleBlur("keySearch")}
          onInput={(e) => {
            if (e.target.value.startsWith(" ")) {
              e.target.value = "";
            }
          }}
          onKeyDown={handleKeyDown}
        />
        <div
          className="position-absolute d-flex align-items-center top-0 right-0"
          onClick={() => handleClickSearch()}
        >
          <AiOutlineSearch size={20} />
        </div>
      </div>
      <button
        onClick={handleRefreshData}
        style={{
          borderRadius: "10px",
          padding: "5px",
          backgroundColor: "#fff",
        }}
      >
        <TfiReload size={20} />
      </button>
    </div>
  );
};
