import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import {
  getProducts,
  resetState,
  selectProduct,
} from "../../features/product/productSlice";
import { ListItem } from "./ListItem";
import Pagination from "../../components/pagination";
import { Search } from "./Search";

const Productlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [offset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const params = useMemo(() => {
    return {
      keyword: keyword,
      offset,
      limit: 10,
    };
  }, [keyword, offset]);

  const getData = useCallback(() => {
    dispatch(getProducts(params));
    dispatch(getProducts());
    dispatch(resetState());
  }, [dispatch, params]);

  useEffect(() => {
    getData();
  }, [getData]);

  const productState = useSelector(selectProduct);
  const { data, totalPage } = productState;

  const handleSearch = (keyword) => {
    params.keyword = keyword;
    setKeyword(keyword);
    setCurrentPage(1);
    getData(params);
  };

  //---------pagination-----------
  const handelChangePage = async (page) => {
    params.offset = (page - 1) * params.limit;
    getData();
    setCurrentPage(page);
  };

  const handelPrevPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      params.offset = (prevPage - 1) * params.limit;
      getData();
      setCurrentPage(prevPage);
    } else {
      return;
    }
  };

  const handelNextPage = async () => {
    if (currentPage < totalPage) {
      const nextPage = currentPage + 1;
      params.offset = (nextPage - 1) * params.limit;
      getData();
      setCurrentPage(nextPage);
    } else {
      return;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div
          className="d-flex align-items-center justify-content-center gap-2 mb-0"
          style={{
            backgroundColor: "#143A51",
            borderRadius: "0.4rem",
            padding: "0.3rem",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => navigate("/admin/product")}
        >
          <h5 className="mb-0 text-white">Thêm sản phẩm</h5>
          <BsPlusCircle
            size={30}
            style={{
              cursor: "pointer",
              fontWeight: "bold",
            }}
          />
        </div>
        <Search handleSearch={handleSearch} />
      </div>
      <ListItem data={data} />
      <div className="d-flex align-items-center justify-content-end mt-0">
        <Pagination
          totalPage={totalPage}
          onchangePage={handelChangePage}
          currentPage={currentPage}
          onchangePrevPage={handelPrevPage}
          onchangeNextPage={handelNextPage}
        />
      </div>
    </>
  );
};

export default Productlist;
