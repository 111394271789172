import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../features/customers/customerSlice";
import { ListItem } from "./ListItem";

const Customers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const customerState = useSelector((state) => state?.customer?.customers);

  return (
    <div>
      <h3 className="mb-4 title">Danh sách khách hàng</h3>
      <div>
        <ListItem data={customerState} />
      </div>
    </div>
  );
};

export default Customers;
