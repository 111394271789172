import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productService from "./productService";
import { toast } from "react-toastify";

export const createProducts = createAsyncThunk(
  "product/create",
  async (data, thunkAPI) => {
    try {
      return await productService.createProduct(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getProducts = createAsyncThunk(
  "product/getAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await productService.getProducts(params);
      if (response.data.result) {
        const result = {
          data: response?.data?.data,
          totalPage: response?.data?.totalPage,
          msg: response?.data?.data?.msg,
        };
        return result;
      } else {
        return rejectWithValue(response?.data?.error[0]);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateStatus = createAsyncThunk(
  "product/updateStatus",
  async (dataStatus, thunkAPI) => {
    const id = dataStatus?.id;
    const status = dataStatus?.active;
    try {
      const data = {
        status: status,
      };
      const response = await productService.updateStatus(id, data);
      if (response.result) {
        const results = {
          id: id,
          status: status,
          msg: response.msg,
        };
        toast.success(response.msg);
        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateFlashSale = createAsyncThunk(
  "product/updateFlashSale",
  async (dataFlashSale, thunkAPI) => {
    const id = dataFlashSale?.id;
    const flashSale = dataFlashSale?.active;
    try {
      const data = {
        flashSale: flashSale,
      };
      const response = await productService.updateFlashSale(id, data);
      if (response.result) {
        const results = {
          id: id,
          flashSale: flashSale,
          msg: response.msg,
        };
        toast.success(response.msg);
        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateDisplayHome = createAsyncThunk(
  "product/displayHome",
  async (dataDisplayHome, thunkAPI) => {
    const id = dataDisplayHome?.id;
    const home = dataDisplayHome?.active;
    try {
      const data = {
        home: home,
      };
      const response = await productService.displayHome(id, data);
      if (response.result) {
        const results = {
          id: id,
          home: home,
          msg: response.msg,
        };
        toast.success(response.msg);
        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getProduct = createAsyncThunk(
  "product/get",
  async (slug, thunkAPI) => {
    try {
      return await productService.getProduct(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getById = createAsyncThunk(
  "product/getbyid",
  async (id, thunkAPI) => {
    try {
      return await productService.getById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateProduct = createAsyncThunk(
  "product/update",
  async (productData, thunkAPI) => {
    try {
      const response = await productService.updateProduct(productData);
      if (response) {
        const results = {
          msg: response.msg,
          dataUpdate: response.updatedProduct,
        };
        return results;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteAProduct = createAsyncThunk(
  "product/delete",
  async (slug, thunkAPI) => {
    try {
      return await productService.deleteProduct(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const resetState = createAction("Reset_all");

const initialState = {
  product: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  data: [],
  dataUpdate: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        const { data } = action.payload;
        state.data = state.data?.length > 0 ? state.data : [];
        state.data = [data, ...state.data];
      })
      .addCase(createProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
        state.totalPage = action.payload.totalPage;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dataUpdate = action?.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dataUpdate = action?.payload?.data;
      })
      .addCase(getById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.dataUpdate;
        if (state.isSuccess) {
          toast.success("Cập nhật thành công");
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedProduct = action.payload;
      })
      .addCase(deleteAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateFlashSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFlashSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateFlashSale.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateDisplayHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDisplayHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateDisplayHome.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export const selectProduct = (state) => state?.product;

export default productSlice.reducer;
